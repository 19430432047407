import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    fingerID: "",
  },
  getters: {
    fingerID: state => state.fingerID,
  },
  mutations: {
    setFingerID(state, fingerID) {
      state.fingerID = fingerID;
    },
  },
  actions: {
    setFingerID({ commit }, fingerID) {
      commit("setFingerID", fingerID);
    },
  },
  modules: {},
});
