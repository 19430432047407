export default  [
    { //sign
        path: "/downLoad",
        name: "appShareDownLoad",
        component: () => import("@/views/appShare/downLoad/index.vue"),
        meta: {
            title: "快艺学APP",
        },
    },
    {
        path: "/appShare/entry",
        name: "appShareEntry",
        component: () => import("@/views/appShare/entry/index.vue"),
        meta: {
            title: "音乐考级",
        },
    },
  ];
  