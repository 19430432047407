import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;
import "amfe-flexible";

import Vant from "vant";
import "vant/lib/index.css";

import {
  singleReq,
  multipleReq
} from '@/utils/req.js'
import api from '@/api/index.js'
import './assets/tailwind.css'




window.$req = singleReq
window.$multipleReq = multipleReq
window.api = api
Vue.use(Vant);
Vue.config.ignoredElements = ["wx-open-launch-app"];
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
