import axios from "axios";
import store from "@/store";
import Fingerprint2 from "fingerprintjs2";
import { isIos } from "@/utils/common.js";
 //意思是携带cookie信息,保持session的一致性
 axios.defaults.withCredentials =true
if (store.getters["fingerID"].length > 0) {
} else {
  Fingerprint2.get(components => {
    const values = components.map(function(component, index) {
      if (index === 0) {
        //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
        return component.value.replace(/\bNetType\/\w+\b/, "");
      }
      return component.value;
    });
    // 生成最终id murmur
    const murmur = Fingerprint2.x64hash128(values.join(""), 31);
    store.commit("setFingerID", murmur);
    // window.localStorage.setItem("fingerID", murmur);
  });
}

function randomString(e) {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n;
}
// 创建 axios 实例
let http = axios.create({
  // headers: {'Content-Type': 'application/json'},
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    device: store.getters["fingerID"],
    "device-token": "",
    "device-model": isIos ? "iPhone" : "Android",
    "device-os": "",
    "device-product": "",
    "device-type": isIos ? 2 : 1,
    "device-size": "",
    "os-image-height": "",
    "os-image-width": "",
    "app-version": "",
    "interface-code": "",
    "os-latitude": "",
    "os-longitude": "",
    nonce: randomString(16),
    timestamp: parseInt(new Date().getTime() * 1000),
    "secret-key": "",
    signature: "",
    "app-system": "h5",
  },
});

// 添加请求拦截器
http.interceptors.request.use(
  async config => {
    // 请求发送前进行处理

    if (config.headers.device.length == 0) {
      //未获取到浏览器指纹
      await Fingerprint2.get(components => {
        const values = components.map(function(component, index) {
          if (index === 0) {
            //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
            return component.value.replace(/\bNetType\/\w+\b/, "");
          }
          return component.value;
        });
        // 生成最终id murmur
        const murmur = Fingerprint2.x64hash128(values.join(""), 31);
        store.commit("setFingerID", murmur);
      });
      config.headers.device = store.getters["fingerID"];
      return config;
    } else {
      return config;
    }
  },
  error => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  response => {
    let { data } = response;
    return data;
  },
  error => {
    let info = {};
    let { status, statusText, data } = error.response;
    if (!error.response) {
      info = {
        code: 5000,
        msg: "Network Error",
      };
    } else {
      // 此处整理错误信息格式
      info = {
        code: status,
        data: data,
        msg: statusText,
      };
    }
    return Promise.reject(info);
  }
);

export default http;
