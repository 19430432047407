/**h5 => 端 */

import { setStore, getStore, clearStore } from "@/utils/storage";

//无回调使用
export const fetchClientFunction = data => {
  window.setupWebViewJavascriptBridge(bridge => {
    bridge.callHandler("clientFunction", data, res => {
      if (data.type == "token") {
        if (!!res) setStore("accessToken", res);
        else clearStore("accessToken");
      }
      if (data.type == "style") {
        //if (res == 0) setStore("themeStyle", 'daily');
        //else if (res == 1) setStore("themeStyle", 'dark');
        //else setStore("themeStyle", 'daily');
      }
    });
  });
};

/**端 => h5 */
export const setWebFunction = cb => {
  window.setupWebViewJavascriptBridge(bridge => {
    bridge.registerHandler("webFunction", (data, fn) => {
      const resData = JSON.parse(data);
      cb(resData);
      fn && fn("");
    });
  });
};

//有回调使用
export const getClientFunction = (data, cb) => {
  window.setupWebViewJavascriptBridge(bridge => {
    bridge.callHandler("clientFunction", data, res => {
      cb(res);
    });
  });
};
