import request from "@/utils/request";
import wx from "weixin-js-sdk";
import { isWeixin } from "@/utils/common";
const baseURL = process.env.NODE_ENV === "test" ? "https://kan0519.cztv.tv/api/v1" : "https://kcz.cztv.tv/api/v1";

export function init() {
  if (!isWeixin) {
    return;
  }
  request({
    url: baseURL + "/share-config/wx-js-config",
    method: "post",
    data: {
      share_url: location.href.split("#")[0],
      apis: [],
    },
  }).then((res) => {
    const config = res.data.data.config;
    wx.config({
      debug: false, // 开启调试模式
      appId: config.appId, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳
      nonceStr: config.nonceStr, // 必填，生成签名的随机串
      signature: config.signature, // 必填，签名，见附录1
      jsApiList: config.jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      openTagList: config.openTagList, //微信开放标签
    });
    wx.ready(function() {
      console.log("ready");
    });
  });
}

export function wxShare(shareTitle, shareImg, shareLink, shareSummary) {
  // shareLink = shareLink.replace("/#/", "/?#/");
  if (!isWeixin) {
    return;
  }
  // request({
  //   url: baseURL + "/share-config/wx-js-config",
  //   method: "post",
  //   data: {
  //     share_url: location.href.split("#")[0],
  //     apis: [],
  //   },
  // }).then((res) => {
  //   const config = res.data.data.config;
  //   wx.config({
  //     debug: false, // 开启调试模式
  //     appId: config.appId, // 必填，公众号的唯一标识
  //     timestamp: config.timestamp, // 必填，生成签名的时间戳
  //     nonceStr: config.nonceStr, // 必填，生成签名的随机串
  //     signature: config.signature, // 必填，签名，见附录1
  //     jsApiList: config.jsApiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  //     openTagList: config.openTagList, //微信开放标签
  //   });

  console.log(shareLink);
  wx.ready(() => {
    //分享到朋友圈
    wx.updateAppMessageShareData({
      title: shareTitle, // 分享时的标题
      desc: shareSummary,
      link: shareLink, // 分享时的链接
      imgUrl: shareImg, // 分享时的图标
      success: function() {
        console.log("分享成功");
      },
      cancel: function() {
        console.log("取消分享");
      },
    });
    //分享给朋友
    wx.updateTimelineShareData({
      title: shareTitle,
      desc: shareSummary,
      link: shareLink,
      imgUrl: shareImg,
      success: function() {
        console.log("分享成功");
      },
      cancel: function() {
        console.log("取消分享");
      },
    });
  });
  // });
}
export function wx_lunch() {}

// 判断当前微信版本号是否支持
export function is_version() {
  let client = false; // 当前版本号是否支持 (默认不支持)
  let wxInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i); // 微信浏览器信息
  if (wxInfo == null) {
    return client;
  } else {
    // 微信版本号 wxInfo[1] = "7.0.18.1740" (示例)
    //进行split转成数组进行判断 [7,0,18,1740] (示例)
    let version = wxInfo[1].split(".");
    // 判断版本在7.0.12及以上的版本
    if (version[0] >= 7) {
      if (version[1] >= 0) {
        if (version[2] >= 12) {
          client = true; // 当前版本支持
        }
      }
    }
    if (version[0] >= 8) {
      client = true;
    }
    return client;
  }
}
