import {
    loadObjModules
} from '@/utils/loadModule/importModule.js'
// 导入所有api模块

let apiModules = require.context('./modules', false, /\.js$/)

const apiEnum = loadObjModules(apiModules)
// let apiModules = import.meta.globEager('./modules/**/*.js') 
// let apiEnum = loadModulesFromObj(apiModules)
// console.log(apiEnum,'apiModules')
export default apiEnum