import axios from 'axios'

import {
  getStore,
} from '@/utils/storage.js'

import {
  Toast
} from 'vant'

let pending = [];
let removePending = ele => {
  // let data = JSON.stringify(ele.data || ele.params);
  let data = (ele.data || ele.params);
  for (let p in pending) {
    if (pending[p].path === ele.url + "&" + ele.method + "&" + data) {
      pending[p].cancel();
      pending.splice(p, 1);
    }
  }
};

axios.defaults.timeout = 60000;
axios.defaults.withCredentials = true
axios.defaults.crossDomain= true
// request拦截器
axios.interceptors.request.use(config => {
  let data = config.data || config.params;
  // Authorization
  if (!!getStore("headersData")) {
    let resData = getStore("headersData")
    // Toast(resData['Authorization'])
    let headData = config.headers
    for (let key in resData) {
      // if (resData[key] != '') { //防止接口访问出错
      headData[key] = resData[key]
      // }
    }
    // headData["Authorization"] = resData.token
    config.headers = headData
  }
  // header("Content-Security-Policy: upgrade-insecure-requests");
  // config.headers['Content-Security-Policy'] = 'upgrade-insecure-requests'
  // eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjZhZjU1NzM2LWY4Y2ItNGE5OS1hY2VlLTY4NjEyOThjZjk1NCJ9.ybqJYTl7_x9Gd3zEw8mFunNThuPw2nSZahBLnNuSDI5_OFmSn-6z76fax1YTu2RMUrUGc9lr4FVbGqyLdqLo5w
  // config.headers['Access-Token'] = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImFjY291bnRJZCI6IjYwYWE0NDBjOGIyODRlNDM4NmNmMWJiZWRhODkwY2IwIiwiY2l0eUlkIjoiMDAwIiwiZXhwIjoxNjE1NTE2NTA0LCJjcmVhdGVkIjoxNjE0OTExNzA0Mjc5fQ.Q7B9PHl_b9Poj2KDuQKybdp2XIj0sBPBI-iWjF7glk8chvz7LMsQDLI7keRlA9cMYERrkrLAX8fjFMuisSXmfQ";
  // config.headers['org'] = 2;
  // config.headers['Access-Control-Allow-Origin'] = 'http://192.168.5.162:8083'
  const methodName = config.method
  switch (methodName) {
    case 'post':
      config.headers['Content-Type'] = "application/json"
      config.headers['Accept'] = "*/*"
      break;
    case 'put':
      config.headers['Content-Type'] = "application/json;charset=UTF-8"

      break;
    default:
      config.headers['Content-Type'] = config.headers['Content-Type'] ? config.headers['Content-Type'] : 'application/x-www-form-urlencoded;charset=UTF-8';
      break;
  }


  return config
}, error => {
  Promise.reject(error)
})


const errorHandle = (status, errdata) => {
  const {
    // config,
    data
  } = errdata
  // if (config.method == 'post' || config.method == 'patch') {
  //   if (!!data.errmsg) {
  //     Toast.fail(data.errmsg);
  //   }
  // }
  // console.log(data, "errdata")

  switch (status) {
    case 401:
      break;
    case 403:
      break;
    case 404:
      //   Toast.fail('请求的资源不存在')
      break;
  }
}

// respone拦截器
axios.interceptors.response.use(
  response => {
    if (response.status == 200) { // 成功处理
      // console.log(response.data, "responseCode")
      if (response.data.code != 200) {
        Toast(response.data.msg || '服务端异常,请刷新重试')
        return false
      }
      return response.data
    } else { // 错误处理/
      Toast('服务器开小差了~稍后重试')
      return Promise.reject('error')
    }
  },
  error => {
    // console.log(error,"error")
    // Toast.fail('服务器开小差了~稍后重试')
    // if (error.request.status === 404) {}
    const {
      response
    } = error

    // console.log(response, "error response")
    if (response) {
      errorHandle(response.status, response)
      return Promise.reject(response)
    }
    // errorHandle(error.request.status, error)
  }

);

/**
 * 向url后追加参数 ?name=value&name1=value1
 * 后台只需要用 GetMapping 注解即可，参数上不需要加注解
 * */
/**
 * 向url后追加参数 ?name=value&name1=value1
 * 后台只需要用 GetMapping 注解即可，参数上不需要加注解
 * */
/**
 * post
 * 表单参数是在请求体中，也是name=value&name1=value1的形式在请求体中，
 * 后台只需要用 PostMapping 注解即可，参数上不需要加注解
 * @param url  字符串类型
 * @param data  json类型
 * */
/**
 * 表单参数是在请求体中，也是name=value&name1=value1的形式在请求体中，
 * 后台只需要用 PostMapping 注解即可，参数上不需要加注解
 * @param url  字符串类型
 * @param data  json类型
 * */
/**
 * 参数以json格式传给后， {name:'li',code:'001'}
 * 1. 后台需要用 PostMapping 注解
 * 2. 后台必须是一个参数
 * 3. 参数上必须增加 RequestBody 注解
 * @param url  字符串类型
 * @param data  json类型
 * */


export function singleReq(url, data) {
  // console.log(url, data, "singleReq")
  if (!url) throw new Error("请求的url为空");
  let param = url.match(/\:[a-zA-Z]+/g);
  if (param) {
    param.forEach(ele => {
      let attr = ele.substr(1);
      url = url.replace(ele, data[attr]);
      delete data[attr];
    });
  }
  let method = url.match(/^(PUT|DELETE|GET|POST|UPLOAD)\s/);
  if (method) {
    url = url.replace(method[0], "");
    method = method[0].trim();
  } else {
    method = "GET";
  }
  switch (method.toLowerCase()) {
    case "delete":
      return axios.delete(url, {
        params: data
      });
    case "post":
      return axios.post(url,
        data
      );
    case "upload":
      return axios({
        url: url,
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      })
    case "put":
      return axios.put(url, data);
    case "patch":
      return axios.patch(url, data);
    default:

      return axios({
        url: url,
        method: 'get',
        params: data
      });
  };
}




/**
 * params urls [] 多个请求的集合
 */
export function multipleReq(options) {
  let arr = [],
    keys = []
  for (let key in options) {
    keys.push(key)
    arr.push(options[key])
  }
  return axios.all(arr).then(
    axios.spread(function () {
      let result = {};
      for (let i = 0; i < arguments.length; i++) {
        let item = arguments[i];
        if (item) {
          if (item.data && item.data.data) {
            result[keys[i]] = item.data.data;
          } else {
            result[keys[i]] = item;
          }
        }
      }
      return result;
    })
  );
}