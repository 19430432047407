import url_base from '@/config/settingConfig/base'
//用户系列
const {
  BASE_API,
  BASE_ART_API,
} = url_base

export default {
  sendSmsUser:  `${BASE_API}/user/sendSms`, // 短信验证码
  codeLogin:  `POST ${BASE_API}/user/codeLogin`, // 登录
  deptInfoDetail:  `${BASE_ART_API}/deptInfo/share`, // 机构表详情
  testUser: `${BASE_API}/User/info`, // 用户信息 示例

}
