<template>
  <div class="index">
    <img src="@/assets/images/index.jpg" class="bg" />
    <div class="box">
      <img src="@/assets/images/logo.png" class="logo" />
      <div class="title">快艺学客户端</div>
      <a href="https://apps.apple.com/cn/app/%E7%9C%8B%E5%B8%B8%E5%B7%9E/id1500233592" class="btn ripple" v-if="isIos">立即下载</a>
      <a href="https://a.app.qq.com/o/simple.jsp?pkgname=tv.cztv.kanchangzhou" class="btn ripple" v-else>立即下载</a>
    </div>
    <div class="bottom">Copyright@2022 南京恰好信息科技</div>
  </div>
</template>

<script>
  import linkApp from "@/utils/linkApp";
  import { wxShare, init } from "@/utils/wxShare";
  import { isIos } from "@/utils/common";
  export default {
    name: "index",
    data() {
      return {
        linkApp: linkApp,
        isIos: isIos(),
      };
    },
    created() {
      setTimeout(() => {
        wxShare("快艺学APP", "https://sharekcz.cztv.tv/cbs.png", "https://sharekcz.cztv.tv/index", "常州，教我如何不想她");
      }, 0);
    },
  };
</script>

<style lang="scss" scoped>
  .index {
    padding-top: 1rem;
    margin: 0 auto;
    height: 100vh;
    position: relative;
    background-color: #aee0f9;
    .bg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .box {
      margin-top: 10%;
      position: relative;
      z-index: 100;
    }
    .logo {
      width: 20%;
      height: auto;
      display: block;
      margin: 0 auto;
    }
    .title {
      margin: 1rem 0;
      width: 100%;
      text-align: center;
      font-weight: 700;
      font-size: 30px;
      color: #000;
    }
    .btn {
      margin: 0 auto;
      border-radius: 40px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      width: 50%;
      color: #fff;
      font-size: 20px;
      display: block;
      background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
    }
    .bottom {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: #2b040a;
    }
  }
</style>
