/**
 * 多模块引入简写
 * @param {*} ms 模块
 */
 export function loadRestModules(ms) {
    let modules = {}
    ms.keys().forEach(k => {
      let n = k.substring(2, k.length - 3)
      modules[n] = ms(k).default
    })
    return modules
  }

export function loadObjModules(ms) {
    let modules = {}
    console.log(ms.keys(), "ms")
    ms.keys().forEach(k => {
        let n = k.substring(2, k.length - 3)
        Object.assign(modules, ms(k).default)
    })
    return modules
}

export function loadModulesFromObj(apiModules) {
    let apiEnum = new Object()
    Object.values(apiModules).forEach(item=>{
        Object.assign(apiEnum,item.default)
    })
    return apiEnum
}