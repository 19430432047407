import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
Vue.use(VueRouter);

// 批量引入
import {
  loadRestModules
} from '@/utils/loadModule/importModule.js'
// 业务组件
let routeModules = require.context('../config/routeConfig/modules', false, /\.js$/)
// 通用组件
const routeEnum = Object.values(loadRestModules(routeModules))
console.log('routeEnum', routeEnum)
const routes = [{
    path: "/entry",
    name: "entry",
    component: () => import("@/views/appShare/entry/index.vue"),
    meta: {
      title: "",
    },
  },
  {
    // poster
    path: "/poster",
    name: "poster",
    component: () => import("@/views/appShare/poster/index.vue"),
    meta: {
      title: "海报",
    },
  },
  {
    path: "/downLoad",
    name: "downLoad",
    component: () => import("@/views/appShare/downLoad/index.vue"),
    meta: {
      title: "",
    },
  },
  {
    path: "*", // 页面不存在的情况下会跳到404页面
    redirect: "/",
    name: "notFound",
    hidden: true,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
  let url = window.location.href;
  // if (url.includes("?from=")) {
  //   // 判断是否携带了 from 参数，这一步灵活变通，只要能判断出是从微信分享链接进来的就 OK
  //   url = url.split("?from")[0] + "#" + url.split("#")[1]; // 利用正则表达式去掉微信携带的 ?from=singlemessage&isappinstalled=0 这串参数，如果这串参数对于你当前的页面有用处的话，可以重新拼接到你正常的链接后面去
  //   window.location.href = url; // 重定向到正常链接
  // } else {
  //   next();
  // }
});

export default router;