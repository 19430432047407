<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { init } from "@/utils/wxShare";
import {
  fetchClientFunction,
  getClientFunction,
  setWebFunction,
} from "@/utils/interface";
import { initBridge } from "@/utils/jsBridge";
import { isWeixin } from "@/utils/common";

export default {
  name: "App",
  data() {
    return {
      isWeixin: isWeixin(),
    };
  },
  methods: {},
  mounted() {
    // initBridge();
    // let data = {
    //   type: "token",
    //   params: "",
    // };
    // fetchClientFunction(data);
  },
  created() {
    if (this.isWeixin) {
      const oScript = document.createElement("script");
      oScript.type = "text/javascript";
      oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
      oScript.onload = init();
      document.body.appendChild(oScript);
    } else {
    }
  },
};
</script>
<style lang='less'>
@import "./assets/less/index.less";
</style>
<style lang="scss">
#app {
  max-width: 750px;
  margin: 0 auto;
}
.open-launch-app {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.container {
  padding-bottom: 100px;
  max-width: 750px;
  margin: 0 auto;
}
//文章标题
.article-title {
  width: 92%;
  padding: 20px 0;
  margin: 0 auto;
  font-size: 24px;
  font-weight: 800;
  line-height: 1.36em;
  color: #1f1f1f;
  opacity: 1;
}
//文章信息
.article-info {
  width: 92%;
  margin: 0 auto;
  color: #939393;
  opacity: 1;
  display: flex;
  flex-wrap: wrap;
  line-height: 1.55;
  li {
    font-size: 12px;
    padding-right: 30px;
  }
}
//文章内容
.article-content {
  overflow: hidden;
  margin: 0 auto;
  width: 92%;
  padding: 20px 0;
  font-weight: 400;
  line-height: 1.56em;
  color: #363636;
  opacity: 1;
  position: relative;
  transition: all 0.5s;
  line-height: 1.56em;
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.66em;
    margin-bottom: 25px;
    font-family: PingFang SC;
    color: #333333;
  }
  * {
    max-width: 100% !important;
    box-sizing: border-box;
  }
  img {
    max-width: 100% !important;
    height: auto !important;
  }
  table {
    max-width: 100% !important;
  }
  section {
    max-width: 100% !important;
  }
}

//全屏横线
.horizontal-line {
  width: 100%;
  height: 5px;
  background-color: #f0f0f0;
}
//向下的箭头
.arrow {
  position: absolute;
  z-index: 10;
  left: 50%;
  // transform: translateX(-50%);
  margin-left: -20px;
  bottom: 20px;
  width: 40px;
  height: auto;
}
//模糊效果
.vague {
  position: relative;
  height: 300px;
  overflow: hidden;
  &::after {
    content: " ";
    position: absolute;
    width: 100%;
    left: 0;
    height: 30%;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
  }
}
</style>
